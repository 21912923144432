import http from "@/http/http-common";
// const resource = "/api/frontend";
class CommonSerive {
    getMenu() {
        return http.get(`/menu`);
    }
    getInformation() {
        // return http.get(`${resource}/information`);
        return http.get(`/common`);
    }
    getLang() {
        // return http.get(`${resource}/information`);
        return http.get(`/languge`);
    }
}

export default new CommonSerive();
